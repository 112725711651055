<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-4/5 md:w-1/2 lg:w-2/5 xl:w-1/4 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col xs:w-full sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Backup Code Input</h4>
                  <p>Enter backup code</p>
                </div>

                <div>
                  
                  <form @submit.prevent="handleSubmit">
                    <vs-input
                      autofocus="autofocus"
                      v-validate="'required'"
                      type="number"
                      name="backupCode"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Enter backup code"
                      v-model="backupCode"
                      class="w-full mt-8" />
                    
                    <!-- <span class="text-danger text-sm">{{ errors.first('email') }}</span> -->
                    
                    <div class="float-right my-5">
                      <router-link to="/login/twofactor">Enter two factor code</router-link>  
                    </div>
                    <div class="my-5">
                      <router-link to="/login">Login Page</router-link>  
                    </div>
                    <vs-button class="w-full float-right mb-8" v-on:click="handleSubmit">Submit</vs-button>
                    <vs-input type="submit" value="Submit" class="hidden" />
                    
                  </form>

                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
   

  </div>
</template>

<script>
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'

export default {
  mixins: [TpNotificationMixin],
  data () {
    return {
      backupCode: null
    }
  },
  computed: {
    oneTimeToken () { 
      return this.$store.state.auth.oneTimeTwoFactorToken
    }
  },
  methods: {
    handleSubmit () {
      this.$vs.loading();
      this.$http.post('api/auth/token/twofactorbackup', { backupCode: this.backupCode, oneTimeToken: this.oneTimeToken})
      .then(response => {
        console.log(this.$route.query.redirect)
        const token = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        this.$store.dispatch('auth/persistTokens', { token, refreshToken} )
        this.$router.push(this.$route.query.redirect || response.data.startPageUrl || '/')
      })
      .catch(error => {
        this.$_notifyFailureByResponseData(error.response.data);
      })
      .finally(() => this.$vs.loading.close())
    }
  }
}
</script>

<style>

</style>